import React from "react";
import "./clientJobDetails.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ErrorCode, UsersEnums } from "../../../../services/constant";
import { AlertMessage, ImageName } from "../../../../enums";
import { ApiCall, ApiCallClient } from "../../../../services/middleware";
import { Decoder } from "../../../../services/auth";
import { toast, ToastContainer } from "react-toastify";
import { inputEmptyValidate } from "../../../../validators";
import Select, { components } from "react-select";
import {

  MultiSelectBox,
  SelectBox,
} from "../../../Admin/SharedComponents/inputText";
import history from "../../../../history";
import ReactLoader from "../../../Loader";
import {
  consoleLog,
  getCallDurationData,
  getUtcDateWithMonthName,
  set12hourTimeFormatLocal,
  set12hourTimeFormatUtc,
  SetDatabaseDateFormat,
  SetDateFormat,
  SetTimeFormat,
  SetUSAdateFormat,
  SetUSAdateFormatV2,
} from "../../../../services/common-function";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { IMAGE_PATH_ONLY, LOCAL_HOST } from "../../../../services/config/api_url";
import EditJob from "../../../Admin/Manage_Interpretation_jobs/JobDetails/EditJobDetails";
import Button from '@mui/material/Button';
import TimeSelectModal from "./TimeSelectModal";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import MainChatPage from "../../../ChatPages/Mainpage";
import Modal from 'react-bootstrap/Modal';
import ChatGroupList from "../../../Admin/Manage_Interpretation_jobs/JobDetails/ChatGroupList";
import { FcInfo } from "react-icons/fc";
import Box from '@mui/material/Box';
import DeclineQuoteModal from "../DeclineQuote/DeclineQuoteModal";

// .............................................................

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
    borderRadius: "6px",
    // width: "60%",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "yellow";
    return {
      ...styles,
      color: isFocused ? "grey" : "white",
      //   backgroundColor: isDisabled ? "red" : "white",
      color: "#000",
      // width: "60%",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

// ...................For Action Modal..........................
const styleCancelModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "500px",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  // p: 4,
  borderRadius: "15px"
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
        style={{ width: "17px" }}
      />
    </components.DropdownIndicator>
  );
};

const followUpTimeArr = [
  {
    label: "8:00 AM - 9:00 AM",
    value: 0,
  },
  {
    label: "9:00 AM - 10:00 AM",
    value: 1,
  },
  {
    label: "10:00 AM - 11:00 AM",
    value: 2,
  },
  {
    label: "11:00 AM - 12:00 AM",
    value: 3,
  },
  {
    label: "12:00 AM - 01:00 PM",
    value: 4,
  },
  {
    label: "01:00 PM - 02:00 PM",
    value: 5,
  },
  {
    label: "02:00 PM - 03:00 PM",
    value: 6,
  },
  {
    label: "03:00 PM - 04:00 PM",
    value: 7,
  },
];

export default class ClientJobDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: true,
      requestId: "",
      clientId: "",
      clientFirstName: "",
      clientLastName: "",
      clientName: "",
      clientEmailId: "",
      clientPhone: "",
      clientIndustryType: "",
      countryCode: "",
      //   .................jobDetails...........
      jobId: "",
      appointmentType: "",
      jobType: "",
      language: "",
      date: "",
      serviceAddress: "",
      siteContant: "",
      consumer: "",
      notesByClient: "",
      notesBy7C: "",

      // ................sendQuote............
      quoteId: "",
      quoteStatus: 0,
      targetAdience: "",
      location: "",
      dateTime: "",
      notesFromClient: "",
      deliveryType: "",
      duration: "",
      rushFee: 0,
      interpretationFee: 0,
      bookingFee: 0,
      nightFee: 0,
      cancelFee: 0,
      weekendFee: 0,


      particularsArr: [],
      particular: "",
      particularAmount: [],
      totalParticularAmt: 0,
      particularAmt: 0,
      particularDataArr: [],
      particularAmountDataArr: [],
      totalAmount: 0,

      appointmentTypeArr: [],
      appointmentTypeData: [],
      languageArr: [],

      sourceLangData: {},
      targetLangData: {},
      listData: [],
      isView: false,

      allChecked: false,
      vendorAssigned: [],
      targetLanguage: "",
      bidFee: 0,
      totalBidFee: 0,
      bidVendorName: "",
      bidVendorId: "",
      isVendorAssigned: false,

      completeSummary: {},
      mislleniousExpenses: [],
      miscTotal: 0.0,
      availabileData: {},
      payable: [],

      allRating: [
        {
          label: "None",
          value: "",
        },
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      selectedFilterRating: {},
      filterRating: "",
      allWeekDay: [
        {
          label: "None",
          value: "",
        },
        {
          label: "Sunday",
          value: "0",
        },
        {
          label: "Monday",
          value: "1",
        },
        {
          label: "Tuesday",
          value: "2",
        },
        {
          label: "Wednesday",
          value: "3",
        },
        {
          label: "Thursday",
          value: "4",
        },
        {
          label: "Friday",
          value: "5",
        },
        {
          label: "Saturday",
          value: "6",
        },
      ],
      selectedFilterWeek: {},
      filterWeek: "",
      filterDistance: "",
      otherService: "",
      otherServiceData: "",

      interpreterCheckIn: "",
      arrivalTimeCheckIn: "true",
      startTimeCheckIn: "",
      endTimeCheckIn: "",
      followUpCheckIn: "",

      rating: "",
      reason: "",
      completionData: {},
      followUpDate: "",
      followUpTimeData: {},

      reqId: "",
      actionModal: false,
      modalActionArr: [],
      selectedModalActionArr: {},
      modalNotes: "",
      modalActionData: {},
      modalDataPos: {},

      showTimeModal: false,
      timeType: "",
      actualArrivalTime: "",
      actualStartTime: "",
      actualEndTime: "",
      modHr: "",
      modMin: "",
      modAmpm: "",
      quoteRemarks: "",
      assignedVendor: "",

      addParticipantShow: false,
      chatParticipants: [],
      selectedChatParticipants: [],
      chatGroup: [],
      selectedChatGroupValue: '',
      chatGroupName: "",
      postJobStat: 0,
      clientPrePay: 0,
      allJobDetails: {},
      isBtnClick: false,
      isFollowUp: "",
      callRating: 0,

      viewQuoteTab: "0",
      actualJobDuration: "",
      viewNoteModal: false,
      viewNote: "",
      loader: false,

      viewDeclineModal: false,
      additionalJobInfo: {},
      isScheduled: 0,
      clientFollowUpSent: 0,
      cancelledReason: "",
      leiName: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("backdrop").style.display = "none";

    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);

    // ........................for client details.......................

    if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.CLIENT) {
      this.setState({
        userType: UsersEnums.APPLICATION_ROLE.CLIENT,
      });
    } else if (
      authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN
    ) {
      this.setState({
        userType: UsersEnums.APPLICATION_ROLE.SUPER_ADMIN,
      });
    }

    // .....  ..............LEI Data..........................
    if (authData) {
      let data = {
        clientId: authUser.data.userid,
      };
      this.getLEIdata(data);
    }

    let mainData = this.props.location,
      preData = mainData.state;
    // consoleLog("Predata>>>", preData);
    if (preData === undefined) {
      return history.push("/clientAllJobs");
    } else {
      this.setState({
        reqId: preData
      })
      this.load();
    }
    // this.load();

    var classInstance = this;

    var viewModal = document.getElementById("viewModal");
    var bidModal = document.getElementById("bid-modal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == viewModal) {
        classInstance.closeViewModal();
      } else if (event.target == bidModal) {
        classInstance.closeBidModal();
      }
    };
  }

  load = async () => {
    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);
    let languageArrData = [],
      languageObjData = {},
      languageResArrData = [],
      clientDetails = {},
      jobDetails = {},
      taskLanguage = "",
      quoteData = {},
      quoteInfo = {},
      targetLanguage = "",
      completeSummary = {},
      mislleniousExpenses = [],
      miscTotal = 0.0,
      payable = [],
      otherService = "",
      otherServiceData = "",
      actualJobDuration = "";

    let mainData = this.props.location,
      preData = mainData.state;
    this.setState({
      requestId: preData,
    });
    let detailData = {
      requestId: preData,
    };

    // .............................FOR CLIENT INFO..........................

    if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.CLIENT) {
      let data = {
        clientid: authUser.data.userid,
      },
        userInfo = [];

      let res = await ApiCall("fetchclientinfo", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        if (payload.data.length > 0) {
          if (payload.data[0].userInfo && payload.data[0].userInfo.length > 0) {
            userInfo = payload.data[0].userInfo[0];
            this.setState({
              clientName: userInfo.clientName,
              clientPhone: userInfo.adminPhone,
            });
          }
        }
      }
    }

    // .............................................

    let detailsRes = await ApiCall("getJobDetails", detailData);

    let detailPayload = Decoder.decode(detailsRes.data.payload);

    consoleLog("Client Job details: >>>>>>>>>>>>>>>>>> ", detailPayload);
    this.setState({
      allJobDetails: detailPayload.data
    })
    if (detailPayload.data.clientDetails) {
      clientDetails = detailPayload.data.clientDetails;
    }
    if (detailPayload.data.jobDetails) {
      jobDetails = detailPayload.data.jobDetails;
      this.fetchChatParticipants(jobDetails.id);
      this.fetchChatGroup(jobDetails.id)
    }
    if (Object.keys(detailPayload.data.quoteInfo).length > 0) {
      quoteInfo = detailPayload.data.quoteInfo;
      if (
        quoteInfo.additionalFee != null ||
        quoteInfo.additionalFee != undefined ||
        quoteInfo.additionalFee != ""
      ) {
        quoteData = JSON.parse(quoteInfo.additionalFee);
      }

      if (quoteInfo.total === 0) {
        this.setState({
          isView: false,
        });
      } else {
        this.setState({
          isView: true,
        });
      }
    }
    if (detailPayload.data.taskList.length > 0) {
      taskLanguage =
        detailPayload.data.taskList[0].sourceLanguage +
        " --> " +
        detailPayload.data.taskList[0].targetLanguage;
      targetLanguage = detailPayload.data.taskList[0].targetLanguageId;
    } else {
      taskLanguage = detailPayload.data.jobDetails.sourceLanguage;
    }

    //................ For all Vendor.................
    let vData = {
      requestId: preData,
      targetLanguageId: targetLanguage,
      distance: "",
      rating: "",
      weekIndex: "",
      hourlyRate: "",
    };

    this.getAvailableVendors(vData);

    if (jobDetails.quoteStatus === 10) {

      //...............Get complete summury............

      let summuryRes = await ApiCall("getJobCompleteSummary", detailData);
      if (
        summuryRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        summuryRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(summuryRes.data.payload);
        consoleLog("Complete Job Summary", payload.data);
        if (payload.data.jobSummary) {
          completeSummary = payload.data.jobSummary;
          this.setCompleteSummaryTime(completeSummary);
        }
        if (payload.data.duration) {
          actualJobDuration = getCallDurationData(payload.data.duration);
        }
        if (payload.data.miscJobExpense.length > 0) {
          mislleniousExpenses = payload.data.miscJobExpense;
          payload.data.miscJobExpense.map((data) => {
            miscTotal = parseFloat(miscTotal) + parseFloat(data.amount);
          });
        }
      }

      //...............Get Payable and Receivable Data............

      // let payableRes = await ApiCall(
      //   "fetchAccountReceivabaleInterpretation",
      //   detailData
      // );
      let payableRes = await ApiCall(
        "fetchAccountReceivabaleJob",
        detailData
      );
      // consoleLog("Fetch payable Request : ", detailData)
      if (
        payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(payableRes.data.payload);
        consoleLog("Payment Info", payload.data);
        if (payload.data.details.length > 0) {
          payable = payload.data.details;
        }
      }
    }

    if (jobDetails.appointmentType !== "F2F") {
      otherService = jobDetails.otherServices;
      otherServiceData = jobDetails.otherServicesData;
    }

    // ...........................................

    this.setState({
      // sourceLangData: languageObjData,
      // targetLangData: languageObjData,
      // languageArr: languageArrData,
      // .......... For Client Details..........
      clientId: jobDetails.clientId,
      clientFirstName: clientDetails.fName,
      clientLastName: clientDetails.lName,
      clientEmailId: clientDetails.businessEmail,
      clientName: clientDetails.clientName,
      clientIndustryType: clientDetails.industtryType,
      clientPhone: clientDetails.adminPhone,
      clientPrePay: clientDetails.isPrepay,
      //............For Job Details  tab..........
      quoteStatus: jobDetails.quoteStatus,
      jobId: jobDetails.jobId,
      appointmentType: jobDetails.appointmentType,
      jobType: jobDetails.jobType,
      language: taskLanguage,
      date: jobDetails.scheduleDate,
      countryCode: clientDetails.adminCountryCode,
      serviceAddress:
        jobDetails.location === null ||
          jobDetails.location === undefined ||
          jobDetails.location === ""
          ? "N/A"
          : jobDetails.location,
      siteContant:
        jobDetails.siteContact === undefined ||
          jobDetails.siteContact === null ||
          jobDetails.siteContact === ""
          ? "N/A"
          : jobDetails.siteContact,
      consumer: jobDetails.consumer,
      notesByClient: jobDetails.noteByClient,
      notesBy7C: jobDetails.noteFor7C,
      quoteId:
        quoteInfo.quoteId === null ||
          quoteInfo.quoteId === undefined ||
          quoteInfo.quoteId === ""
          ? "N/A"
          : quoteInfo.quoteId,
      targetAdience:
        jobDetails.targetAudience === null ||
          jobDetails.targetAudience === undefined ||
          jobDetails.targetAudience === ""
          ? "N/A"
          : jobDetails.targetAudience,
      location:
        jobDetails.location === null ||
          jobDetails.location === undefined ||
          jobDetails.location === ""
          ? "N/A"
          : jobDetails.location,
      dateTime: jobDetails.scheduleDate,
      scheduleTime: jobDetails.scheduleTime,
      serviceProvider: jobDetails.noOfserviceProvider,
      notesFromClient: jobDetails.noteByClient,
      assignedVendor: jobDetails.vendorName ? jobDetails.vendorName : "NA",
      deliveryType: jobDetails.isOutOfTown === 1 ? "OUT OF TOWN" : "IN TOWN",
      duration: jobDetails.duration,
      clientIndustryType: clientDetails.industtryType,
      interpretationFee: quoteInfo.fee,
      bookingFee: quoteInfo.bookingFee,
      nightFee: quoteInfo.nightFee,
      cancelFee: quoteInfo.cancelFee,
      weekendFee: quoteInfo.weekendFee,

      rushFee: quoteInfo.rushFee,
      totalAmount: quoteInfo.total,
      particularsArr: quoteData,
      targetLanguage: targetLanguage,
      completeSummary: completeSummary,
      mislleniousExpenses: mislleniousExpenses,
      miscTotal: miscTotal,
      payable: payable,
      otherService: otherService,
      otherServiceData: otherServiceData,
      quoteRemarks: quoteInfo.remarks,
      isLoad: false,
      viewQuoteTab: detailPayload.data.viewQuote,
      actualJobDuration: actualJobDuration,
      quoteDeclineReason: quoteInfo.declinedType,
      quoteDeclineDescription: quoteInfo.declinedReason,
      isScheduled: jobDetails.isScheduled,
      additionalJobInfo: Number(jobDetails.isScheduled) === 1 ? jobDetails.additionalJobInfo !== null ? jobDetails.additionalJobInfo : {} : {},
      cancelledReason: jobDetails.rejectReason,
      leiName: jobDetails.leiName ? jobDetails.leiName : "NA"
    });
  };

  setCompleteSummaryTime = async (data) => {
    let arrivalTime = data.isArrivalAprroved === "No" ? data.actualArrivalTime : data.arrivalTime,
      startTime = data.isStartApproved === "No" ? data.actualStartTime : data.startTime,
      endTime = data.isEndApproved === "No" ? data.actualEndTime : data.endTime;

    await this.loaderChange(true)

    this.setState({
      actualArrivalTime: arrivalTime,
      actualStartTime: startTime,
      actualEndTime: endTime,
      startTimeCheckIn: data.postJobStat === 3 ? data.isStartApproved === "Yes" ? "true" : "false" : "",
      endTimeCheckIn: data.postJobStat === 3 ? data.isEndApproved === "Yes" ? "true" : "false" : "",
      rating: data.clientRatings,
      reason: data.prefferedReason,
      interpreterCheckIn: (data.isStartApproved === "No" || data.isStartApproved === "Yes") ? data.isPrefVendor === "Yes" ? "true" : "false" : "",
      postJobStat: data.postJobStat,
      isFollowUp: data.clientFollowup.toString(),
      callRating: data.callQuality,
      clientFollowUpSent: Number(data.clientFollowUpSend)
    })
    await this.loaderChange(false)

  }

  getLEIdata = async (data) => {
    let arr = [];
    let res = await ApiCall("fetchLeiByClient", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // console.log("PAyload>>>", payload.data);
      if (payload.data.leiList.length > 0) {
        payload.data.leiList.map((ldata) => {
          arr.push({
            label: ldata.name,
            value: ldata.userId,
          });
        });
      }
      this.setState({
        allLeiList: payload.data.leiList,
        leiOptionList: arr,
      });
    }
  };

  interpreterCheckIn = async (e) => {
    consoleLog("InterpreterCheckIn >>>>> ", e.target.value);

    await this.loaderChange(true);
    this.setState({
      interpreterCheckIn: e.target.value,
    });
    await this.loaderChange(false);
  };

  arrivalTimeCheck = (e) => {
    if (e.target.value === "false") {
      this.modalTimeSet(this.state.actualArrivalTime);
      this.setState({
        timeType: "arrival",
        showTimeModal: true
      })
    }
    this.setState({
      arrivalTimeCheckIn: e.target.value,
    });
  };

  startTimeCheckIn = (e) => {
    if (e.target.value === "false") {
      this.modalTimeSet(this.state.actualStartTime);
      this.setState({
        timeType: "start",
        showTimeModal: true
      })
    }
    this.setState({
      startTimeCheckIn: e.target.value
    });
  };

  endTimeCheckIn = (e) => {
    if (e.target.value === "false") {
      this.modalTimeSet(this.state.actualEndTime);
      this.setState({
        timeType: "end",
        showTimeModal: true
      })
    }
    this.setState({
      endTimeCheckIn: e.target.value
    });
  };

  followUpYes = (e) => {
    this.setState({
      followUpCheckIn: true,
    });
  };
  followUpNo = (e) => {
    this.setState({
      followUpCheckIn: false,
    });
  };

  getAvailableVendors = async (data) => {
    let res = await ApiCall("getVendorsWorkingStatus", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // consoleLog("All Vendor List>>>>>", payload.data)
      let vendorList = [],
        brr = [];

      if (payload.data.vendorList.length > 0) {
        vendorList = payload.data.vendorList;
        let scount = 0;
        payload.data.vendorList.map((aa) => {
          if (aa.isQuoteSent === 1) {
            brr.push(aa.userId);
          }
          if (aa.status === 2) {
            scount++;
          }
        });
        if (scount > 0) {
          this.setState({
            isVendorAssigned: true,
          });
        }
      }
      this.setState({
        listData: vendorList,
        vendorAssigned: brr,
      });
    }
  };

  blankValueCheck = (data) => {
    if (
      data === null ||
      data === undefined ||
      data === "" ||
      data.length === 0
    ) {
      data = "N/A";
      return data;
    } else {
      return data;
    }
  };

  onAppointmentChange = (data) => {
    this.setState({
      appointmentTypeData: data,
    });
  };

  onSourceLangChange = (data) => {
    this.setState({
      sourceLangData: data,
    });
  };

  onTargetLangChange = (data) => {
    this.setState({
      targetLangData: data,
    });
  };
  onParticularChange = (e, index) => {
    let amt = this.state.particularsArr,
      particularData = [],
      particularDataArr = [];
    for (let i = 0; i < this.state.particularsArr.length; i++) {
      if (i === index) {
        amt[i].title = e.target.value;
        particularDataArr.push(amt[i].title);
      }
      particularData.push(amt[i].title);
    }

    this.setState({
      particularsArr: amt,
      particularDataArr: particularData,
    });
  };

  onParticularAmountChange = (e, index) => {
    let data = e.target.value,
      particularAmtDataArr = [];
    var valid = !isNaN(data);

    let amount = e.target.value === "" ? 0 : parseInt(e.target.value);

    let amt = this.state.particularsArr,
      rushfee = this.state.rushFee,
      interpretationFee = this.state.interpretationFee,
      totalAmt = 0,
      sum = 0;
    if (valid) {
      for (let i = 0; i < amt.length; i++) {
        if (i === index) {
          amt[i].amt = amount.toString();
          particularAmtDataArr.push(amt[i].amt);
        }

        sum = sum + parseFloat(amt[i].amt);
      }
      totalAmt = sum + parseFloat(rushfee) + parseFloat(interpretationFee) +
        this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee;
    }
    this.setState({
      particularsArr: amt,
      totalParticularAmt: sum,
      totalAmount: totalAmt
    });
  };
  onRushFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        rushFee: parseFloat(e.target.value),
        totalAmount: totalAmt,
      });
    } else {
      totalAmt =
        totalAmt +
        parseFloat(this.state.interpretationFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        rushFee: 0,
        totalAmount: totalAmt,
      });
    }
  };
  onInterpretationFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
        parseFloat(this.state.rushFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        interpretationFee: parseFloat(e.target.value),
        totalAmount: totalAmt,
      });
    } else {
      totalAmt =
        totalAmt + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
        parseFloat(this.state.rushFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        interpretationFee: 0,
        totalAmount: totalAmt,
      });
    }
  };
  addParticularField = () => {
    let arr = this.state.particularsArr;
    arr.push({
      title: this.state.particular,
      amt: this.state.particularAmt,
    });

    this.setState({
      particularsArr: arr,
    });
  };
  onDeleteParticulars = (index) => {
    let particularArray = this.state.particularsArr;

    let arr = [],
      amt = 0,
      particularAmt = 0;
    for (let i = 0; i < particularArray.length; i++) {
      if (i != index) {
        particularAmt = particularAmt + parseFloat(particularArray[i].amt);
        arr.push(particularArray[i]);
      }
    }
    amt =
      amt +
      particularAmt +
      parseFloat(this.state.rushFee) + this.state.bookingFee + this.state.nightFee + this.state.cancelFee + this.state.weekendFee +
      parseFloat(this.state.interpretationFee);

    this.setState({
      particularsArr: arr,
      totalAmount: amt,
      totalParticularAmt: particularAmt,
    });
  };
  onBack = () => {
    return history.push("/clientAllJobs");
  };

  onCancelSummary = () => {
    return history.push("/clientAllJobs");
  };
  formDateChange = (date) => {
    this.setState({
      followUpDate: SetUSAdateFormat(date),
    });
  };

  onFollowupTimeChange = (dat) => {
    this.setState({
      completionData: this.state.completionData,
      followUpTimeData: dat,
    });
  };

  onSubmitSummary = async () => {

    let errorCount = 0;
    let validateArrivalTime = inputEmptyValidate(this.state.arrivalTimeCheckIn),
      validateStartTime = inputEmptyValidate(this.state.startTimeCheckIn),
      validateEndTime = inputEmptyValidate(this.state.endTimeCheckIn),
      validateRating = inputEmptyValidate(this.state.rating),
      validateInterpreter = inputEmptyValidate(this.state.interpreterCheckIn),
      validateFollowup = inputEmptyValidate(this.state.isFollowUp),
      validateCallRating = inputEmptyValidate(this.state.callRating);

    if (validateArrivalTime === false) {
      toast.error("Please select Arrival Time !!");
      errorCount++;
    } else if (validateStartTime === false) {
      toast.error("Please select Start Time !!");
      errorCount++;
    } else if (validateEndTime === false) {
      toast.error("Please select End Time !!");
      errorCount++;
    } else if (validateRating === false) {
      toast.error("Please Rate your Interpreter !!");
      errorCount++;
    } else if (validateInterpreter === false) {
      toast.error("Please select would you use same Interpreter or not !!");
      errorCount++;
    } else if (validateFollowup === false) {
      toast.error("Please select follow up");
      errorCount++;
    } else if (validateCallRating === false) {
      if (this.state.appointmentType !== "F2F" && this.state.otherService !== 0) {
        toast.error("Please Rate your Call !!");
        errorCount++;
      }
    }

    if (errorCount === 0) {
      let startDate = SetDatabaseDateFormat(SetUSAdateFormatV2(this.state.dateTime)) + " " + this.setPerfectTimeForat(this.state.actualStartTime);
      let endTime = SetDatabaseDateFormat(SetUSAdateFormatV2(this.state.dateTime)) + " " + this.setPerfectTimeForat(this.state.actualEndTime);

      let data = {
        requestId: this.state.requestId,
        serviceId: "45",
        approveArrivalTime: this.state.arrivalTimeCheckIn === "true" ? "1" : "0",
        approveStartTime: this.state.startTimeCheckIn === "true" ? "1" : "0",
        approveEndTime: this.state.endTimeCheckIn === "true" ? "1" : "0",
        clientRatings: this.state.rating.toString(),
        sameInterpreterCheck: this.state.interpreterCheckIn === "true" ? "1" : "0",
        reason: this.state.reason,
        // arrivalTime: this.setPerfectTimeForat(this.state.actualArrivalTime),
        arrivalTime: startDate,
        startTime: startDate,
        endTime: endTime,
        isFollowup: this.state.isFollowUp,
        callQuality: this.state.callRating.toString()
      };

      // this.setState({
      //   isBtnClick: true
      // })

      consoleLog("Request Client Complete Summury::::", data);
      let res = await ApiCall("feedBackCompletionSummary", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Completion Summary submitted successfully");

        this.setState({
          isBtnClick: false
        });
        if (this.state.isFollowUp === "1") {
          return history.push({
            pathname: "/clietFollowUpAppointment",
            state: this.state.reqId,
          });
        }
        this.load();
      }
    }
  };

  setPerfectTimeForat = (val) => {
    let dt = moment(val, ["h:mm A"]).format("HH:mm");
    let str = dt + ":00";
    return str
  }

  onCreateQuote = async () => {
    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    let validateInterpretationFee = inputEmptyValidate(
      this.state.interpretationFee
    );

    // if (validateInterpretationFee === false) {
    if (this.state.interpretationFee === 0) {
      toast.error(AlertMessage.MESSAGE.JOB.EMPTY_FEE, {
        hideProgressBar: true,
      });
      errorCount++;
    }

    if (errorCount === 0) {
      let data = {
        clientId: authUser.data.userid,
        requestId: this.state.requestId,
      };
      let res = await ApiCall("acceptClientQuote", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_ACCEPTED_BY_CLIENT);
        setTimeout(
          function () {
            return history.push("/clientAllJobs");
          }.bind(this),
          1000
        );
      } else {
        toast.error(res.message);
      }
    }
  };

  allVendorCheck = (e) => {
    let arr = this.state.listData;
    let brr = [];
    if (e.target.checked) {
      arr.map((data) => {
        data.isQuoteSent = 1;
        brr.push(data.userId);
      });
      this.setState({
        allChecked: true,
        listData: arr,
        vendorAssigned: brr,
      });
    } else {
      arr.map((data) => {
        data.isQuoteSent = 0;
      });
      brr = [];
      this.setState({
        allChecked: false,
        listData: arr,
        vendorAssigned: brr,
      });
    }
  };
  openViewModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("viewModal").style.display = "block";
    document.getElementById("viewModal").classList.add("show");
  };
  closeViewModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("viewModal").style.display = "none";
    document.getElementById("viewModal").classList.remove("show");
  };
  openBidModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("bid-modal").style.display = "block";
    document.getElementById("bid-modal").classList.add("show");
  };
  closeBidModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("bid-modal").style.display = "none";
    document.getElementById("bid-modal").classList.remove("show");
  };

  vendoeAssigned = async (vid) => {
    let apiObj = {
      requestId: this.state.requestId,
      vendorId: vid,
    };
    let res = await ApiCall("assignTraningInterpretion", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_ASSIGNED_SUCCESS);
      this.closeBidModal();
      setTimeout(
        function () {
          return history.push("/adminViewAllJobs");
        }.bind(this),
        1000
      );
    }
  };

  acceptClient = async () => {
    let data = {
      clientId: this.state.clientId,
      requestId: this.state.requestId,
    };
    // consoleLog("Request data::", data)
    // let res = await ApiCall("acceptClientQuote", data);
    let res = await ApiCall("acceptClientQuotev3", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      window.scrollTo(0, 0);
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_ACCEPTED);
      // setTimeout(
      //   function () {
      return history.push("/clientAllJobs");
      //   }
      //     .bind(this),
      //   1000
      // );
    } else {
      toast.error(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR);
    }
  };

  acceptAndPayQuoteClient = async () => {
    let data = {
      requestId: this.state.requestId,
    };
    let res = await ApiCall("updateQuotePayment", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      window.scrollTo(0, 0);
      this.acceptClient();
    } else {
      toast.error(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR);
    }
  };

  onDecline = async (value) => {
    this.setState({
      isBtnClick: true
    })
    let data = {
      clientId: this.state.clientId,
      requestId: this.state.requestId,
      cancelType: value.reason,
      remarks: value.description
    };
    consoleLog("Decline Quote Request >>> ", data)
    let res = await ApiCallClient("rejectClientQuote", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      window.scrollTo(0, 0);
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_REJECTED);
      this.setState({
        isBtnClick: false
      })
      return history.push("/clientAllJobs");
    } else {
      this.setState({
        isBtnClick: false
      })
    }
  };

  filterModal = () => {
    this.openFilterModal();
  };
  // .............filter modal function...................
  openFilterModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("filter-model").style.display = "block";
    document.getElementById("filter-model").classList.add("show");
  };

  closeFilterModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("filter-model").style.display = "none";
    document.getElementById("filter-model").classList.remove("show");
  };

  filterRatingChange = (value) => {
    this.setState({
      filterRating: value.value,
    });
    // let rating = value.value;
    let vData = {
      requestId: this.state.requestId,
      targetLanguageId: this.state.targetLanguage,
      distance: this.state.filterDistance,
      rating: value.value,
      weekIndex: this.state.filterWeek,
      hourlyRate: "",
    };

    this.getAvailableVendors(vData);
  };

  filterWeekChange = (value) => {
    this.setState({
      filterWeek: value.value,
    });
    let vData = {
      requestId: this.state.requestId,
      targetLanguageId: this.state.targetLanguage,
      distance: this.state.filterDistance,
      rating: this.state.filterRating,
      weekIndex: value.value,
      hourlyRate: "",
    };

    this.getAvailableVendors(vData);
  };

  filterDistanceChange = (e) => {
    this.setState({
      filterDistance: e.target.value,
    });
    let vData = {
      requestId: this.state.requestId,
      targetLanguageId: this.state.targetLanguage,
      distance: e.target.value,
      rating: this.state.filterRating,
      weekIndex: this.state.filterWeek,
      hourlyRate: "",
    };

    this.getAvailableVendors(vData);
  };

  onDownloadMisc = (pos) => {
    window.open(
      IMAGE_PATH_ONLY + this.state.mislleniousExpenses[pos].incidentals,
      "_blank"
    );
  };

  ratingChanged = (newRating) => {
    this.setState({
      rating: newRating,
    });
  };

  ratingChangedInterpreter = (newRating) => {
    this.setState({
      callRating: newRating,
    });
  };

  onJobNotesChange = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  joinVideo = (appointmentType, jobId) => {
    // consoleLog("AppointmentType: ", appointmentType);
    // consoleLog("Job Type::", jobId);
    localStorage.setItem("All_job_data", JSON.stringify(this.state.allJobDetails));
    window.open(LOCAL_HOST + `/servicePage/${appointmentType}/${jobId}`);
  }
  closeTimeModal = () => {
    this.setState({
      showTimeModal: false
    })
  }
  setCorectTime = (val) => {
    this.closeTimeModal();

    if (this.state.timeType === "arrival") {
      this.setState({
        actualArrivalTime: val
      })
    } else if (this.state.timeType === "start") {
      this.setState({
        actualStartTime: val
      })
    } else if (this.state.timeType === "end") {
      this.setState({
        actualEndTime: val
      })
    }
  }

  editCorectTime = (timeType) => {

    if (timeType === "arrival") {
      this.modalTimeSet(this.state.actualArrivalTime);
      this.setState({
        showTimeModal: true
      })
    } else if (timeType === "start") {
      this.modalTimeSet(this.state.actualStartTime);
      this.setState({
        showTimeModal: true
      })
    } else if (timeType === "end") {
      this.modalTimeSet(this.state.actualEndTime);
      this.setState({
        showTimeModal: true
      })
    }
  }

  modalTimeSet = (value) => {
    let temp1 = value.split(" ");
    let temp2 = temp1[0].split(":");

    this.setState({
      modHr: temp2[0],
      modMin: temp2[1],
      modAmpm: temp1[1]
    })
  }




  fetchChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    // consoleLog("FETCH NOTES REQ >>> ", apiObj)
    let res = await ApiCall("getChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);
      // consoleLog("FETCH Chat Participants Job", payload.data);

      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })
      payload.data.staffList.map((st) => {
        let name = st.userTypeId == 1 ? st.name : st.name + " (" + st.userId + " - Staff)";
        arr.push({
          label: name,
          value: st.userId,
          userTypeId: st.userTypeId,
          id: st.id
        });
      });

      // consoleLog("All Chat Participants >>> ", arr)

      this.setState({
        chatParticipants: arr
      })
    }
  }

  getUserType = (id) => {
    let str = "";
    if (id == 3) {
      str = "Client";
    } else if (id == 4) {
      str = "vendor";
    } else if (id == 7) {
      str = "Client Supervisor";
    } else {
      str = "Client Requester";
    }

    return str;
  }

  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }

  closeParticipantModal = () => {
    this.setState({
      addParticipantShow: false
    })
  }

  onSelectChatParticipants = (value) => {
    this.setState({
      selectedChatParticipants: value
    })
  }

  changeChatGroupName = (e) => {
    this.setState({
      chatGroupName: e.target.value
    })
  }

  submitParticipants = async () => {
    let errorCounter = 0;
    if (this.state.chatGroupName === "") {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_GROUP_NAME);
      return false;
    } else if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: this.state.chatGroupName,
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("submitChatJoinRequest", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Group initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }

  changeChatGroup = (value) => {
    this.setState({
      selectedChatGroupValue: value._id
    })
    this.showChatPage(value._id)
  }

  fetchChatGroup = async (id) => {
    let apiObj = {
      jobId: id
    };

    let res = await ApiCall("fetchChatJoinRequest", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("All Chat Group", payload.data);
      let arr = [];
      if (payload.data.length > 0) {
        payload.data.map((item, i) => {
          let obj = {
            label: item.groupName,
            value: item
          }
          arr.push(obj)

        })
      }
      this.setState({
        chatGroup: arr
      })
    }
  }

  showChatPage = (value) => {
    return value !== "" ? <MainChatPage jobId={value} jobAlphaId={this.state.jobId} /> : <></>

  }

  /* Single chat initiate function start */

  onSelectChatParticipantsForSingle = (value) => {
    let arr = [];
    arr.push(value);
    this.setState({
      selectedChatParticipants: arr
    })
  }

  submitParticipantsForSingle = async () => {
    let errorCounter = 0;
    if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: '',
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("singleJobChatInitiate", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Chat initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }
  /* Single chat initiate function End */

  quoteView = () => {
    // console.log("quote status >>>>>>>>>>> ", this.state.quoteStatus)
    if (this.state.quoteStatus === 1 ||
      this.state.quoteStatus === 2 ||
      this.state.quoteStatus === 7) {
      if (this.state.viewQuoteTab == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }



  _onClientFollowUpCheck = (id) => {
    if (id === "1") {
      this.setState({
        isFollowUp: "1"
      })
    } else {
      this.setState({
        isFollowUp: "0"
      })
    }
  };

  openViewNoteModal = (data) => {
    this.setState({
      viewNote: data.description,
      viewNoteModal: true
    })
  }

  closeViewNoteModal = () => {
    this.setState({
      viewNote: "",
      viewNoteModal: false
    })
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  openDeclineModal = () => {
    this.setState({
      viewDeclineModal: true
    })
  }

  closeDeclineModal = () => {
    this.setState({
      viewDeclineModal: false
    })
  }

  render() {
    const customStylesDropdown = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
        borderRadius: "10px",
        height: 45,
        minHeight: 45,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "yellow";
        return {
          ...styles,
          color: isFocused ? "grey" : "white",
          //   backgroundColor: isDisabled ? "red" : "white",
          color: "#000",
          cursor: isDisabled ? "not-allowed" : "default",
        };
      },
    };
    return (
      <React.Fragment>
        <ToastContainer hideProgressBar theme="colored" />
        <div class="component-wrapper" hidden={!this.state.isLoad}>
          <ReactLoader />
        </div>

        <div className="component-wrapper" hidden={this.state.isLoad}>
          {/* <ReactLoader /> */}
          <div
            className="vn_frm"
            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
          >
            {" "}
            <Link to="/clientDashboard">Dashboard</Link> /{" "}
            <Link to="/clientAllJobs">Interpretation Jobs</Link> / Job Details
          </div>
          <div className="job-details-tab jobdltste _fl sdw">
            <ul
              className="nav nav-tabs"
              style={{ justifyContent: "flex-start" }}
            >
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#jobdetails"
                //   href="#comp_sum"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Job Details
                  </div>
                </a>{" "}
              </li>
              {/* {this.state.quoteStatus == 7 ||
                this.state.quoteStatus == 9 ||
                this.state.quoteStatus == 6 ||
                this.state.quoteStatus == 8 ||
                this.state.quoteStatus == 10 ||
                this.state.quoteStatus == 11 ? <React.Fragment>

              </React.Fragment> : <React.Fragment>
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#editjobdetails"
                  //   href="#comp_sum"
                  >
                    <div className="taber">
                      <figure>
                        <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                      </figure>{" "}
                      Edit Job Details
                    </div>
                  </a>{" "}
                </li>
              </React.Fragment>} */}

              <li className="nav-item">
                {" "}
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#editjobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Edit Job Details
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#sendqute">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TABBAR} />
                    </figure>
                    View Quote
                  </div>
                </a>{" "}
              </li>

              {this.state.quoteStatus === 10 ?
                <React.Fragment>
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link" data-toggle="tab" href="#comp_sum">
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.TABBAR} />
                        </figure>
                        Completion Summary
                      </div>
                    </a>{" "}
                  </li>
                </React.Fragment> : <></>
              }

              {/* {this.state.quoteStatus === null ||
                this.state.quoteStatus === "" ||
                this.state.quoteStatus === undefined ||
                this.state.quoteStatus === 7 ? (
                <React.Fragment />
              ) : this.state.quoteStatus === 10 ? (
                <React.Fragment>
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link" data-toggle="tab" href="#comp_sum">
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.TABBAR} />
                        </figure>
                        Completion Summary
                      </div>
                    </a>{" "}
                  </li>
                </React.Fragment>
              ) : (
                this.quoteView() ?
                  <React.Fragment>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" data-toggle="tab" href="#sendqute">
                        <div className="taber">
                          <figure>
                            <img src={ImageName.IMAGE_NAME.TABBAR} />
                          </figure>
                          View Quote
                        </div>
                      </a>{" "}
                    </li>
                  </React.Fragment> : <></>
              )} */}



              {/* {this.state.quoteStatus === 2 ||
              this.state.quoteStatus === 3 ||
              this.state.quoteStatus === 6 ||
              this.state.quoteStatus === 8 ? (
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" data-toggle="tab" href="#vendoroff">
                    <div className="taber">
                      <figure>
                        <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                      </figure>
                      Available Vendors
                    </div>
                  </a>{" "}
                </li>
              ) : (
                <React.Fragment></React.Fragment>
              )} */}
              {this.state.quoteStatus === 10 &&
                this.state.completeSummary.postJobStat > 1 ? (
                <>
                  {this.state.payable.length > 0 ?
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#payablerecavable"
                      >
                        <div className="taber">
                          <figure>
                            <img src={ImageName.IMAGE_NAME.CHAT_ICON} />
                          </figure>
                          Payments
                        </div>
                      </a>
                    </li> :
                    <></>
                  }
                </>
              ) : (
                <></>
              )}
              {/* <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#clientdetails">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                    </figure>
                    Client Details
                  </div>
                </a>{" "}
              </li> */}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              {/* <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li> */}
            </ul>

            <div className="tab-content">
              <div className="tab-pane  active" id="jobdetails">
                <div className="job-section-tab">
                  <table
                    width="100%"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td width="50%" align="left">
                          Job ID
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobId}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Appointment Type
                        </td>
                        <th width="50%" align="right">
                          <div className="f2f-b">
                            {this.state.appointmentType}
                          </div>
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Job Type
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobType}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Language
                        </td>
                        <th width="50%" align="right">
                          {this.state.language}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Date
                        </td>
                        <th width="50%" align="right">
                          {SetDateFormat(this.state.date)}{" "}
                          {this.state.scheduleTime}
                        </th>
                      </tr>
                      {this.state.appointmentType === "F2F" ?
                        <tr>
                          <td width="50%" align="left">
                            Service Location Address
                          </td>
                          <th width="50%" align="right">
                            {this.state.serviceAddress}
                          </th>
                        </tr> : <></>
                      }

                      <tr>
                        <td width="50%" align="left">
                          No. of Interpreters
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceProvider}
                        </th>
                      </tr>

                      {this.state.appointmentType === "F2F" ?
                        <tr>
                          <td width="50%" align="left">
                            Site Contact
                          </td>
                          <th width="50%" align="right">
                            {this.state.siteContant}
                          </th>
                        </tr> : <></>
                      }

                      <tr>
                        <td width="50%" align="left">
                          Client
                        </td>
                        <th width="50%" align="right">
                          {this.state.consumer}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          LEI
                        </td>
                        <th width="50%" align="right">
                          {this.state.leiName}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Notes For Interpreter
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesByClient}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left" class="blueText">
                          Notes For <span>7C Admin</span>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr>
                      {this.state.quoteStatus == 11 ?
                        <tr>
                          <td width="50%" align="left">
                            Job Cancelled Reason
                          </td>
                          <th width="50%" align="right">
                            &nbsp;{this.state.cancelledReason}
                          </th>
                        </tr> : <></>
                      }
                      {this.state.appointmentType !== "F2F" ? (
                        <React.Fragment>
                          {this.state.quoteStatus >= 10 && this.state.quoteStatus < 12 ? <></> :
                            <tr>
                              <td width="50%" align="left" class="blueText">

                                <span>7C Lingo</span> voice/video services

                              </td>
                              <th width="50%" align="right">
                                &nbsp;
                                {this.state.otherService === 1 ?
                                  <React.Fragment>
                                    {this.state.quoteStatus === 8 || this.state.quoteStatus === 9 ?
                                      <Button variant="contained" onClick={() => this.joinVideo(this.state.appointmentType, this.state.jobId)}>Join</Button>
                                      : "Not yet assigned to a interpreter"
                                    }
                                  </React.Fragment> : "NO"}
                              </th>
                            </tr>
                          }
                          {this.state.otherService === 0 ? (
                            <React.Fragment>
                              <tr>
                                <td width="50%" align="left">
                                  3rd party services
                                </td>
                                <th width="50%" align="right">
                                  &nbsp;{this.state.otherServiceData}
                                </th>
                              </tr>
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      {/* <tr>
                        <td width="50%" align="left">
                          <p class="notes">
                            Notes From <span>7C Lingo</span>
                          </p>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr> */}
                      {/* {this.state.quoteStatus >= 8 ?
                        <tr>
                          <td width="50%" align="left">
                            Assigned Vendor
                          </td>
                          <th width="50%" align="right">
                            {this.state.assignedVendor}
                          </th>
                        </tr> : <></>
                      } */}
                      {Number(this.state.isScheduled) === 1 ? <>
                        <tr>
                          <td width="50%" align="left">
                            Job Preferences
                          </td>
                          <th width="50%" align="right">
                            {Object.keys(this.state.additionalJobInfo).length > 0 ? <>
                              {this.state.additionalJobInfo.type === '2' ? <><span className="job_prefrences"><p>{this.state.additionalJobInfo.name}</p><br /><p>Reschedule Date : {this.state.additionalJobInfo.value}</p></span></> : <><>{this.state.additionalJobInfo.name}</></>}
                            </> : <>N/A</>}
                          </th>
                        </tr>
                      </> : <></>}
                    </tbody>
                  </table>
                </div>

                {/* .......................................................................... */}
              </div>

              {/* ................for edit job details page................. */}
              <div className="tab-pane" id="editjobdetails">
                <div className="job-section-tab">
                  <EditJob mainData={this.state.reqId} />
                </div>
              </div>

              <div className="tab-pane" id="sendqute">
                <div className="job-section-tab">
                  <div className="view-quote-details-wrap">
                    <h3>view quote details</h3>
                    <p>
                      <span>Job ID</span>
                      {this.state.jobId}
                    </p>
                    {/* <p>
                      <span>Target Audience</span> {this.state.targetAdience}
                    </p> */}
                    <p>
                      <span>Location</span> {this.state.location}
                    </p>
                    <p>
                      <span>Appontment Type</span> {this.state.appointmentType}
                    </p>
                    <p>
                      <span>Date & Time</span>{" "}
                      {SetDateFormat(this.state.dateTime)}{" "}
                      {this.state.scheduleTime}
                    </p>
                    <p>
                      <span>Notes from Client</span>
                      {this.state.notesFromClient}
                    </p>
                    <p>
                      <span>Industry Type</span> {this.state.clientIndustryType}
                    </p>
                    <p>
                      <span>Language</span> {this.state.language}
                    </p>
                  </div>

                  <div className="add-more-field">
                    <div className="table-listing-app create">
                      <div className="creat_quot">
                        <h3>
                          {!this.state.isView ? "Create Quote" : "View Quote"}
                        </h3>
                        <div className="depr_table">
                          <div className="table-responsive_mb">
                            {/* <div class="addmore_service text-right">
                              <a href="javascript:void(0)">
                                <div
                                  onClick={this.addParticularField}
                                  style={{ marginTop: "8px" }}
                                >
                                  Add Additional Field
                                </div>
                              </a>
                            </div> */}
                            <table
                              width="100%"
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                            >
                              <tbody>
                                {this.state.appointmentType === "F2F" ? (
                                  <tr>
                                    <th width="50%" align="left">
                                      Delivery Type
                                    </th>
                                    <th width="50%" align="right">
                                      {this.state.deliveryType}
                                    </th>
                                  </tr>
                                ) : (
                                  <React.Fragment />
                                )}

                                {/* <tr>
                                  <td width="50%" align="left">
                                    No. of service provider
                                  </td>
                                  <th width="50%" align="right">
                                    {this.state.serviceProvider}
                                  </th>
                                </tr> */}

                                <tr>
                                  <td width="50%" align="left">
                                    Duration
                                  </td>
                                  <td width="50%" align="right">
                                    {this.state.duration}
                                  </td>
                                </tr>

                                {Number(this.state.rushFee) !== 0 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Rush Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled
                                              className="inputfield flr"
                                              type="text"
                                              value={this.state.rushFee}
                                              style={{ width: "75%" }}
                                              onChange={(e) =>
                                                this.onRushFeeChange(e)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> : <></>
                                }

                                <tr>
                                  <td width="50%" align="left">
                                    Interpretation Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div
                                          className="input-group"
                                          style={{ justifyContent: "end" }}
                                        >
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text dollar"
                                              id="basic-addon1"
                                            >
                                              $
                                            </span>
                                          </div>

                                          <input
                                            disabled
                                            className="inputfield flr"
                                            type="text"
                                            value={this.state.interpretationFee}
                                            style={{ width: "75%" }}
                                            onChange={(e) =>
                                              this.onInterpretationFeeChange(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <input
                                      className="inputfield flr"
                                      type="text"
                                      value={this.state.interpretationFee}
                                      onChange={(e) =>
                                        this.onInterpretationFeeChange(e)
                                      }
                                    /> */}
                                  </td>
                                </tr>

                                {this.state.bookingFee > 0 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Booking Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled={true}
                                              className="inputfield flr"
                                              type="number"
                                              value={parseFloat(this.state.bookingFee).toFixed(3)}
                                              style={{ width: "75%" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> : <></>}

                                {this.state.nightFee > 0 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Night Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled={true}
                                              className="inputfield flr"
                                              type="number"
                                              value={parseFloat(this.state.nightFee).toFixed(3)}
                                              style={{ width: "75%" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> : <></>}

                                {this.state.weekendFee > 0 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Weekend Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled={true}
                                              className="inputfield flr"
                                              type="number"
                                              value={parseFloat(this.state.weekendFee).toFixed(3)}
                                              style={{ width: "75%" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> : <></>}

                                {this.state.cancelFee > 0 ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Cancel Fee
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>

                                            <input
                                              disabled={true}
                                              className="inputfield flr"
                                              type="number"
                                              value={parseFloat(this.state.cancelFee).toFixed(3)}
                                              style={{ width: "75%" }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> : <></>}
                                {this.state.particularsArr.length > 0 ? (
                                  <React.Fragment>
                                    {this.state.particularsArr.map(
                                      (item, key) => (
                                        <tr>
                                          <td width="50%" align="left">
                                            <input
                                              disabled
                                              className="inputfield flr"
                                              type="text"
                                              placeholder="Particulars"
                                              value={item.title}
                                              onChange={(e) =>
                                                this.onParticularChange(e, key)
                                              }
                                            />
                                          </td>
                                          <td width="50%" align="right">
                                            <div className="row">
                                              <div className="col-md-6"></div>
                                              <div className="col-md-6">
                                                <div
                                                  className="input-group"
                                                  style={{
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <div class="input-group-prepend">
                                                    <span
                                                      class="input-group-text dollar"
                                                      id="basic-addon1"
                                                    >
                                                      $
                                                    </span>
                                                  </div>

                                                  <input
                                                    disabled
                                                    className="inputfield flr"
                                                    type="text"
                                                    placeholder="Enter Amount"
                                                    value={item.amt}
                                                    style={{ width: "75%" }}
                                                    onChange={(e) =>
                                                      this.onParticularAmountChange(
                                                        e,
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {/* <div className="col-md-1 delete-btn">
                                          <img
                                            src={ImageName.IMAGE_NAME.TRASH_BTN}
                                            onClick={() =>
                                              this.onDeleteParticulars(key)
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div> */}
                                            </div>
                                            {/* <input
                                        className="inputfield flr"
                                        type="text"
                                        placeholder="Enter Amount"
                                        value={item.amt}
                                        onChange={(e) =>
                                          this.onParticularAmountChange(e, key)
                                        }
                                      />
                                      &nbsp;{" "}
                                      <span>
                                        <img
                                          src={ImageName.IMAGE_NAME.TRASH_BTN}
                                          onClick={() =>
                                            this.onDeleteParticulars(key)
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span> */}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment />
                                )}
                                {this.state.quoteRemarks !== "" ?
                                  <tr>
                                    <td width="50%" align="left">
                                      Remarks
                                    </td>
                                    <td width="50%" align="right">
                                      {this.state.quoteRemarks}
                                    </td>
                                  </tr> : <></>
                                }
                                {this.state.quoteStatus == 7 ? <>
                                  <tr>
                                    <td
                                      width="50%"
                                      align="left"
                                    >
                                      Decline reason
                                    </td>
                                    <td width="50%" align="right">
                                      <p>{this.state.quoteDeclineReason}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      width="50%"
                                      align="left"
                                    >
                                      Description (if any)
                                    </td>
                                    <td width="50%" align="right">
                                      <p>{this.state.quoteDeclineDescription}</p>
                                    </td>
                                  </tr>
                                </> : <></>}

                                <tr>
                                  <td
                                    width="50%"
                                    align="left"
                                    style={{ color: "#5ea076" }}
                                  >
                                    Total Payable
                                  </td>
                                  <td
                                    width="50%"
                                    align="right"
                                    style={{ color: "#5ea076" }}
                                  >
                                    $ {parseFloat(this.state.totalAmount)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_button-style m30 _fl text-center">
                    <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={this.onBack}
                    >
                      Back
                    </a>
                    {Number(this.state.interpretationFee) === 0 ? <></> : <>
                      {this.state.viewQuoteTab == 1 ? <>
                        {this.state.quoteStatus === 1 ?
                          <>
                            {!this.state.isBtnClick ?
                              <a
                                href="javascript:void(0)"
                                className="white-btn"
                                style={{ textDecoration: "none", color: "red" }}
                                onClick={() => this.openDeclineModal()}
                              >
                                Decline
                              </a> :
                              <a
                                href="javascript:void(0)"
                                className="white-btn"
                                style={{ textDecoration: "none", color: "red" }}
                              >
                                <i class="fa fa-spinner fa-spin"></i>
                              </a>
                            }
                          </> : <></>
                        }
                        {this.state.quoteStatus === 1 ? (
                          <>
                            {this.state.clientPrePay === 0 ? <>
                              <a
                                href="javascript:void(0)"
                                className="blue-btn"
                                style={{ textDecoration: "none", color: "#fff" }}
                                onClick={this.acceptClient}
                              >
                                Accept
                              </a>
                            </> : <>
                              <a
                                href="javascript:void(0)"
                                className="blue-btn"
                                style={{ textDecoration: "none", color: "#fff" }}
                                onClick={this.acceptAndPayQuoteClient}
                              >
                                Accept & Pay
                              </a>
                            </>
                            }
                          </>
                        ) : (
                          <></>
                        )} </> : <></>
                      }
                    </>
                    }
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="clientdetails">
                <div className="job-section-tab">
                  <h3>Client Information</h3>
                  <div className="c-l-s _fl">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>First Name</h4>
                        <p>{this.state.clientFirstName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Last Name</h4>
                        <p>{this.state.clientLastName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Client</h4>
                        <p>
                          <span className="blue">{this.state.clientName}</span>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Email ID</h4>
                        <p>{this.state.clientEmailId}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Phone Number</h4>
                        <p>
                          {"+"}
                          {this.state.countryCode} {this.state.clientPhone}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Industry Type</h4>
                        <p>{this.state.clientIndustryType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="payablerecavable">
                <div className="acc_payable">
                  <h3>Account Payables</h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Job ID</th>
                          <th style={{ width: "20%" }}>Language</th>
                          <th style={{ width: "15%" }}>Completed On</th>
                          <th style={{ width: "20%" }}>Total Amount $</th>
                          <th style={{ width: "10%" }}>Remarks</th>
                          <th style={{ width: "10%" }}>Status</th>
                          <th style={{ width: "10%" }}>View</th>
                          {/* <th style={{ width: "10%" }}>Action</th> */}
                        </tr>
                        {this.state.payable.length > 0 ? (
                          <React.Fragment>
                            {this.state.payable.map((data) => (
                              <React.Fragment>
                                {data.status === 0 ? <></> :
                                  <tr>
                                    <td>{this.state.jobId}</td>
                                    <td>
                                      {data.sourceLanguage} {">"}{" "}
                                      {data.targetlanguage}
                                    </td>
                                    <td>
                                      {getUtcDateWithMonthName(data.endTime)} |{" "}
                                      {set12hourTimeFormatLocal(data.endTime)}
                                    </td>
                                    <td>${data.totalAmount}</td>
                                    <td>{data.remarks === "" ? "N/A" : data.remarks}</td>
                                    <td>
                                      {data.status === 0 ? (
                                        <React.Fragment>
                                          Verification Pending
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {data.status === 1 ? (
                                            <React.Fragment>
                                              Verified
                                            </React.Fragment>
                                          ) : data.status === 3 ? <>Rejected</> :
                                            <React.Fragment>
                                              Invoice Received
                                            </React.Fragment>
                                          }
                                        </React.Fragment>
                                      )}
                                    </td>
                                    <td>
                                      <FcInfo size={30} onClick={() => this.openViewNoteModal(data)} style={{ cursor: "pointer" }} />
                                    </td>
                                  </tr>
                                }
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              {/* .............................completion summary................. */}

              <div className="tab-pane" id="comp_sum">
                <div className="job-section-tab">
                  {/* {Object.keys(this.state.completeSummary).length > 0 ? <React.Fragment> */}
                  {/* <h2>CLIENT</h2> */}
                  <div className="tbl-iformation">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          {/* <tr>
                            <td width="25%" align="left">
                              Interpreter Arrival Time
                            </td>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.completeSummary.arrivalTime}
                            </th>
                            <th width="25%" align="center">
                              <div
                                className="row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <div className="col-md-2">
                                  <div className="check-field">
                                    <label className="checkbox_btn">
                                      <input
                                        type="radio"
                                        name="radio"
                                        value="true"
                                        checked={
                                          this.state.arrivalTimeCheckIn === "true"
                                        }
                                        onChange={
                                          this.arrivalTimeCheck
                                        }
                                      />
                                      <span className="checkmark3"></span> Yes
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="check-field">
                                    <label className="checkbox_btn">
                                      <input
                                        type="radio"
                                        name="radio"
                                        value="false"
                                        checked={
                                          this.state.arrivalTimeCheckIn ===
                                          "false"

                                        }
                                        onChange={
                                          this.arrivalTimeCheck
                                        }
                                      />
                                      <span className="checkmark3"></span> No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.arrivalTimeCheckIn === "false" ? <>
                                <FiEdit size={20} style={{ float: "right" }} onClick={() => this.editCorectTime("arrival")} />
                                {this.state.actualArrivalTime}</> : ""}
                            </th>
                          </tr> */}
                          <tr>
                            <td width="25%" align="left">
                              Interpreter Start Time
                            </td>
                            <th width="23%" align="center" className="table_text_left">
                              {this.state.completeSummary.startTime}
                            </th>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ?
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <div className="col-md-2">
                                    <div className="check-field">
                                      <label className="checkbox_btn">
                                        <input
                                          type="radio"
                                          name="radioStartTime"
                                          value="true"
                                          checked={
                                            this.state.startTimeCheckIn === "true"

                                          }
                                          onChange={
                                            this.startTimeCheckIn
                                          }
                                          disabled={this.state.postJobStat < 3 ? false : true}
                                        />
                                        <span className="checkmark3"></span> Yes
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="check-field">
                                      <label className="checkbox_btn">
                                        <input
                                          type="radio"
                                          name="radioStartTime"
                                          value="false"
                                          checked={
                                            this.state.startTimeCheckIn === "false"
                                          }
                                          onChange={
                                            this.startTimeCheckIn
                                          }
                                          disabled={this.state.postJobStat < 3 ? false : true}
                                        />
                                        <span className="checkmark3"></span> No
                                      </label>
                                    </div>
                                  </div>
                                </div> :
                                <>
                                  {this.state.startTimeCheckIn === 'true' ? "Yes" : "No"}
                                </>
                              }
                            </th>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ? <>
                                {this.state.startTimeCheckIn === "false" ? <>
                                  <FiEdit size={20} style={{ float: "right" }} onClick={() => this.editCorectTime("start")} /> {this.state.actualStartTime} </> : ""}
                              </> : <>{this.state.actualStartTime}</>}
                            </th>
                          </tr>
                          <tr>
                            <td width="25%" align="left">
                              Interpreter End Time
                            </td>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.completeSummary.endTime}
                            </th>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ?
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <div className="col-md-2">
                                    <div className="check-field">
                                      <label className="checkbox_btn">
                                        <input
                                          type="radio"
                                          name="radioEndTime"
                                          value="true"
                                          checked={
                                            this.state.endTimeCheckIn === "true"

                                          }
                                          onChange={
                                            this.endTimeCheckIn
                                          }
                                          disabled={this.state.postJobStat < 3 ? false : true}
                                        />
                                        <span className="checkmark3"></span> Yes
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="check-field">
                                      <label className="checkbox_btn">
                                        <input
                                          type="radio"
                                          name="radioEndTime"
                                          value="false"
                                          checked={
                                            this.state.endTimeCheckIn === "false"

                                          }
                                          onChange={
                                            this.endTimeCheckIn
                                          }
                                          disabled={this.state.postJobStat < 3 ? false : true}
                                        />
                                        <span className="checkmark3"></span> No
                                      </label>
                                    </div>
                                  </div>
                                </div> :
                                <>
                                  {this.state.endTimeCheckIn === 'true' ? "Yes" : "No"}
                                </>
                              }
                            </th>
                            <th width="25%" align="center" className="table_text_left">
                              {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ? <>
                                {this.state.endTimeCheckIn === "false" ? <>
                                  <FiEdit size={20} style={{ float: "right" }} onClick={() => this.editCorectTime("end")} />{this.state.actualEndTime}</> : ""}
                              </> : <>{this.state.actualEndTime}</>
                              }
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="_fl margin-top-30 br-bx">
                    <div className="row">
                      <div className="col-md-6">
                        <p> Actual Job Duration : {this.state.actualJobDuration}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p> Rate Your Interpreter</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="rt-rating">
                          {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                          <ReactStars
                            count={5}
                            onChange={this.ratingChanged}
                            size={44}
                            half={false}
                            color2="#009fe0"
                            value={this.state.rating}
                            edit={this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ? true : false}
                          />
                          {this.state.rating === 0 ? (
                            <React.Fragment>
                              <a href="javascript:void(0)" className="poor">
                                Very Poor
                              </a>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {this.state.rating ===
                                1 ? (
                                <React.Fragment>
                                  <a
                                    href="javascript:void(0)"
                                    className="poor"
                                  >
                                    Poor
                                  </a>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {this.state.rating === 2 ? (
                                    <React.Fragment>
                                      <a
                                        href="javascript:void(0)"
                                        className="poor"
                                      >
                                        Average
                                      </a>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {this.state.rating === 3 ? (
                                        <React.Fragment>
                                          <a
                                            href="javascript:void(0)"
                                            className="poor"
                                          >
                                            Good
                                          </a>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {this.state.rating === 4 ? (
                                            <React.Fragment>
                                              <a
                                                href="javascript:void(0)"
                                                className="poor"
                                              >
                                                Very Good
                                              </a>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {this.state.rating === 5 ? <React.Fragment>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="poor"
                                                >
                                                  Outstanding
                                                </a> </React.Fragment> : <React.Fragment></React.Fragment>
                                              }
                                            </React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.appointmentType !== "F2F" && this.state.otherService !== 0 ?
                    <div className="_fl margin-top-30 br-bx">
                      <div className="row">
                        <div className="col-md-6">
                          <p> Call Quality</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="rt-rating">
                            {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                            <ReactStars
                              count={5}
                              onChange={(value) =>
                                this.ratingChangedInterpreter(value)
                              }
                              size={44}
                              half={false}
                              color2="#009fe0"
                              value={this.state.callRating}
                              edit={this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ? true : false}
                            />
                            {this.state.callRating === 0 ? (
                              <React.Fragment>
                                <a href="javascript:void(0)" className="poor">
                                  Very Poor
                                </a>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {this.state.callRating ===
                                  1 ? (
                                  <React.Fragment>
                                    <a
                                      href="javascript:void(0)"
                                      className="poor"
                                    >
                                      Poor
                                    </a>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {this.state.callRating === 2 ? (
                                      <React.Fragment>
                                        <a
                                          href="javascript:void(0)"
                                          className="poor"
                                        >
                                          Average
                                        </a>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {this.state.callRating === 3 ? (
                                          <React.Fragment>
                                            <a
                                              href="javascript:void(0)"
                                              className="poor"
                                            >
                                              Good
                                            </a>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {this.state.callRating === 4 ? (
                                              <React.Fragment>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="poor"
                                                >
                                                  Very Good
                                                </a>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {this.state.callRating === 5 ? <React.Fragment>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="poor"
                                                  >
                                                    Outstanding
                                                  </a> </React.Fragment> : <React.Fragment></React.Fragment>
                                                }
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> : <></>
                  }
                  <div className="_fl margin-top-30 br-bx">
                    <div className="row">
                      <div className="col-md-6">
                        <p>Would you use the same Interpreter? *</p>
                      </div>
                    </div>
                    {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ?
                      <div className="row">
                        <div className="col-md-2">
                          <div className="check-field">
                            <label className="checkbox_btn">
                              <input
                                type="radio"
                                name="radiostart"
                                value="true"
                                checked={
                                  this.state.interpreterCheckIn === "true"
                                }
                                onChange={(e) => this.interpreterCheckIn(e)}
                                disabled={this.state.postJobStat < 3 ? false : true}
                              />
                              <span className="checkmark3"></span> Yes
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="check-field">
                            <label className="checkbox_btn">
                              <input
                                type="radio"
                                name="radiostart"
                                value="false"
                                checked={
                                  this.state.interpreterCheckIn === "false"
                                }
                                onChange={(e) => this.interpreterCheckIn(e)}
                                disabled={this.state.postJobStat < 3 ? false : true}
                              />
                              <span className="checkmark3"></span> No
                            </label>
                          </div>
                        </div>
                      </div> :
                      <div className="row">
                        <div className="col-md-12">
                          <span>{this.state.interpreterCheckIn === "true" ? "YES" : "NO"}</span>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="_fl margin-top-30 br-bx">
                    {this.state.interpreterCheckIn !== "" ? (
                      <React.Fragment>
                        {this.state.interpreterCheckIn === "false" ? <React.Fragment>
                          <div className="row">
                            <div className="col-md-6">
                              <p>Reason</p>
                            </div>
                          </div>
                          {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ?
                            <div className="row">
                              <div className="web-form-bx md4">
                                <textarea
                                  rows="2"
                                  placeholder=""
                                  className="in-textarea msg min "
                                  value={this.state.reason}
                                  style={{
                                    height: "100px",
                                    color: "var(--grey)",
                                    borderRadius: "10px",
                                    boxShadow: "2px",
                                    resize: "none",
                                    width: "300px",
                                  }}
                                  onChange={this.onJobNotesChange}
                                  readOnly={this.state.postJobStat < 3 ? false : true}
                                ></textarea>
                              </div>
                            </div> :
                            <div className="row">
                              <div className="col-md-12">
                                <span>{this.state.reason}</span>
                              </div>
                            </div>
                          }
                        </React.Fragment> : <React.Fragment></React.Fragment>
                        }
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>

                  {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ?
                    <div className="_fl margin-top-30 br-bx">
                      <div className="row">
                        <div className="col-md-6">
                          <p>Is there any follow up appointments?</p>
                        </div>
                        <div className="col-md-6">
                          {/* <p className="np">{this.state.completeSummary.followUp}</p> */}
                          {/* <a href="javascript:void(0)" className="_viewdetails">View Details</a>  */}
                          <div className="check-field">
                            <label className="checkbox_btn">
                              <input
                                type="radio"
                                name="clientFollowUp"
                                checked={
                                  this.state.isFollowUp === "1" ? true
                                    : false
                                }
                                onClick={(e) =>
                                  this._onClientFollowUpCheck("1")
                                }
                              />
                              <span className="checkmark3"></span> Yes
                            </label>
                          </div>
                          <div className="check-field">
                            <label className="checkbox_btn">
                              <input
                                type="radio"
                                name="clientFollowUp"
                                checked={
                                  this.state.isFollowUp === "0"
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  this._onClientFollowUpCheck("0")
                                }
                              />
                              <span className="checkmark3"></span> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="_fl margin-top-30 br-bx">
                      <div className="row">
                        <div className="col-md-12">
                          <p>Is there any follow up appointments? *</p>
                        </div>
                        <div className="col-md-12">
                          <span>{this.state.isFollowUp === "1" ? "YES" : "NO"}</span>
                        </div>
                      </div>
                    </div>
                  }


                  <div className="_button-style m30 _fl text-center">
                    {/* <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={this.onCancelSummary}
                    >
                      cancel
                    </a> */}
                    {this.state.postJobStat < 3 && this.state.clientFollowUpSent === 0 ? <>
                      {!this.state.isBtnClick ?
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={this.onSubmitSummary}
                        >
                          Submit
                        </a>
                        :
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none", color: "#fff" }}
                        // onClick={this.onSubmitSummary}
                        >
                          <i class="fa fa-spinner fa-spin"></i>
                        </a>
                      }
                    </>
                      :
                      <></>
                    }
                    {/* {this.state.quoteStatus === 1 ? <>
                      <button type="button" class="btn btn-success" onClick={this.acceptClient}>Accept</button> <span style={{ color: "gray" }}>(On behalf of client)</span>
                    </> : <></>
                    } */}
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="prticipants-area _fl">

                    <div className="row">
                      <div className="col-md-6">
                        {/* <h3>3 Participants</h3> */}
                        <button type="button" className="new_chat_btn" onClick={this.openParticipantModal}>
                          <img src={ImageName.IMAGE_NAME.MENU.CHAT_ICON_BLUE} />
                          <span>New Chat</span>
                        </button>
                      </div>

                      <div className="col-md-6">
                        {/* <a href="javascript:void(0)" className="add-part-btn" onClick={this.openParticipantModal}>
                          + Add Participants
                        </a> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.chatGroup.length > 0 ?
                          <ChatGroupList
                            value={this.state.chatGroup}
                            changeGroupValue={(value) => this.changeChatGroup(value)}
                          /> :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                  {this.showChatPage(this.state.selectedChatGroupValue)}
                  {/* {this.state.selectedChatGroupValue !== "" ? <MainChatPage jobId={this.state.selectedChatGroupValue} /> : <></>} */}
                </div>
              </div>


              <Modal show={this.state.addParticipantShow} onHide={this.closeParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Participants</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <div className="web-form-bx">
                    <div className="frm-label">
                      Group Name
                    </div>
                    <div className="form-input-fields">
                      <input
                        type="text"
                        placeholder="Enter group name..."
                        className="textbox4"
                        style={{
                          borderRadius: "9px",
                          boxShadow:
                            "0px 0px 4px 0px rgb(0 0 0 / 20%)",
                        }}
                        value={this.state.chatGroupName}
                        onChange={this.changeChatGroupName}
                      />
                    </div>
                  </div> */}

                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <SelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipantsForSingle(value)
                        }
                      ></SelectBox>
                      {/* <MultiSelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipantsForSingle(value)
                        }
                      ></MultiSelectBox> */}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsForSingle}>
                    Start Chat
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="tab-pane" id="nofifications">
                Notification
              </div>
            </div>
          </div>
        </div>
        {/* //.................... For availability Modal................... */}

        <div id="viewModal" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="filter-head _fl document-hd">
                <h3 className="text-center center-text">
                  Trainer Availability
                </h3>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.closeViewModal()}
                >
                  X
                </button>
              </div>

              <div className="modal-body">
                <div className="table-listing-app card">
                  <div className="table-responsive">
                    {Object.keys(this.state.availabileData).length > 0 ? (
                      <BidModal value={this.state.availabileData} />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>

                <div className="_button-style _fl text-center">
                  {/* <a className="grey-btn" onClick={() => this.closeModal()}>cancel</a> */}
                  {/* <a className="blue-btn">save</a> */}
                  {/* <a href="#" className="buledark-btn">save & reverse</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //...................... For Bid Modal....................... */}
        <div
          id="bid-modal"
          className="modal fade modelwindow largewindow"
          role="dialog"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p30">
                <div className="mtch-top-text">
                  <p>
                    <span>Job ID</span> {this.state.jobId}
                  </p>
                  <p>
                    <span>Task</span> {this.state.language}
                  </p>
                  <p>
                    <span>Client Name</span> {this.state.clientFirstName}{" "}
                    {this.state.clientLastName}
                  </p>
                </div>

                <div className="mv-text _fl">
                  <h2>Vendor Bid Details</h2>
                </div>

                <div className="matching-vendor-table _fl sdw">
                  <div className="depr_table p10">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th style={{ width: "60%" }}>Vendor Name</th>
                            <th style={{ width: "10%" }} className="th_1">
                              {this.state.bidVendorName}
                            </th>
                          </tr>
                          <tr>
                            <td>Rate / Hour</td>
                            <td>$ {this.state.bidFee}</td>
                          </tr>

                          <tr className="tt-count">
                            <td className="f1">Total Bid</td>
                            <td> $ {this.state.totalBidFee}</td>
                          </tr>
                          {/* <tr>
                            <td>&nbsp;</td>
                            <td  ><a className="bidAssignBtn">Assign</a></td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <a
                        href="javascript:void(0)"
                        className="bidAssignBtn"
                        onClick={() => {
                          this.vendoeAssigned(this.state.bidVendorId);
                        }}
                      >
                        Assign
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..................modal................................. */}
        <div id="filter-model" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-md modal-dialog-centered">
            {/* <!-- Modal content--> */}
            <div className="modal-content">
              <div className="filter-head _fl mdf">
                <h3>Filter by</h3>
                <div className="reset-btn-dp">
                  <button className="reset" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.RESET_BTN}
                      onClick={this.onResetFilter}
                    />
                    Reset
                  </button>
                  <button className="apply" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.BLUE_TICK}
                      onClick={this.onFilterApply}
                    />
                    Apply
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="model-info f-model">
                  <div className="m-select _fl">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "13px" }}>
                            Distance
                          </div>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "13px" }}>
                            Rating
                          </div>
                          <div class="dropdwn" style={{ cursor: "pointer" }}>
                            <SelectBox />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div class="col-md-5">
                        <div class="sf-row">
                          <div class="lable-text" style={{ fontSize: "12px" }}>
                            Department
                          </div>
                          <div
                            class="dropdwn"
                            style={{
                              cursor: "pointer",
                              width: "65%",
                              marginLeft: "100px",
                            }}
                          >
                            <Select
                              styles={customStyles}
                              options={this.state.departmentArr}
                              components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                              }}
                              value={this.state.departmentData}
                              placeholder="Select"
                              onChange={(value) => {
                                this.onDepartmentChange(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showTimeModal ?
          <TimeSelectModal
            show={this.state.showTimeModal}
            close={() => this.closeTimeModal()}
            saveTime={(val) => this.setCorectTime(val)}
            modHr={this.state.modHr}
            modMin={this.state.modMin}
            modAmpm={this.state.modAmpm}
          /> : <></>}
        <div
          class="modal-backdrop fade show"
          id="backdrop"
          style={{ display: "none" }}
        ></div>

        <Modal
          show={this.state.viewNoteModal}
          onHide={this.closeViewNoteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Header closeButton>
            <Modal.Title>Payables Description</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="doc_action_modal">
              <span className="tr_nl">
                <textarea
                  rows="2"
                  placeholder=""
                  className="in-textarea msg min"
                  value={this.state.viewNote}
                  style={{
                    height: "200px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  disabled
                ></textarea>
                {/* {this.state.viewNote} */}
              </span>
            </div>
          </Modal.Body>
        </Modal>

        <DeclineQuoteModal
          open={this.state.viewDeclineModal}
          closeModal={() => this.closeDeclineModal()}
          submitDecline={(value) => this.onDecline(value)} >
        </DeclineQuoteModal>
      </React.Fragment>
    );
  }
}

class BidModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <table width="100%" border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style={{ width: "14%" }}>Sunday</th>
            <th style={{ width: "14%" }}>Monday</th>
            <th style={{ width: "14%" }}>Tuesday</th>
            <th style={{ width: "14%" }}>Wednesday</th>
            <th style={{ width: "14%" }}>Thursday</th>
            <th style={{ width: "14%" }}>Friday</th>
            <th style={{ width: "14%" }}>Saturday</th>
          </tr>
          <tr>
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Sunday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* .............monday................. */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Monday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..............Tuesday................ */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Tuesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..........wednesday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Wednesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................thursday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Thursday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ............friday.................... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Friday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................saturday.......... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Saturday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  }
}

class Schedule extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <img
        style={{
          width: "35px",
          height: "37px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        src={ImageName.IMAGE_NAME.CALENDER4}
        onClick={onClick}
      />
    );
  }
}
