import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from "react-toastify";
import { ImageName } from "../../../../enums";
import history from "../../../../history";
import { Decoder } from "../../../../services/auth";
import { consoleLog, SetDatabaseDateFormat, SetUSAdateFormat } from "../../../../services/common-function";
import { ErrorCode, UsersEnums } from "../../../../services/constant";
import { ApiCall } from "../../../../services/middleware";
import './OutofTown.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class JobAdvanceFunctionalityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.value.jobAdvanceModal,
            isSelect: false,
            isOutOfTown: false,
            isReschedule: false,
            rescheduleDate: "",
            isOverPhone: false,
            isOverVideo: false,
            startDate: this.start14Days()
        }
    }

    start14Days = () => {

        // Calculate the date that is 14 days later from today
        const today = new Date();
        const fourteenDaysLater = new Date(today);
        fourteenDaysLater.setDate(fourteenDaysLater.getDate() + 14);
        return fourteenDaysLater;
    }

    appointmentdateChange = (date) => {
        this.setState({
            rescheduleDate: SetUSAdateFormat(date),
        });
        // consoleLog("Date::", date)
    };

    handleClose = () => {
        this.props.toggle()
    }

    clickChangeYes = async (e) => {
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);

        this.setState({
            isSelect: !this.state.isSelect
        });
        // consoleLog("Job Request Id :", this.props.value.jobRequestId);
        if (e.target.checked) {
            let data = {
                requestId: this.props.value.jobRequestId
            }
            let res = await ApiCall("outOfTownConfirmation", data);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                    return history.push("/adminViewAllJobs");
                } else {
                    return history.push("/clientAllJobs");
                }
            } else {
                toast.error(res.message)
            }
        } else {
            if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                return history.push("/adminViewAllJobs");
            } else {
                return history.push("/clientAllJobs");
            }
        }
    }

    clickChangeNo = (e) => {
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);
        if (e.target.checked) {
            if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                return history.push("/adminViewAllJobs");
            } else {
                return history.push("/clientAllJobs");
            }
        }
    }

    openPopUp = (type) => {
        if (type === "1") {
            // this.setState({
            //     isOutOfTown: true
            // })
            this.onSubmit(type)
        } else if (type === "2") {
            this.setState({
                isReschedule: true
            })
        } else if (type === "3") {
            this.setState({
                isOverPhone: true
            })
        } else if (type === "4") {
            this.setState({
                isOverVideo: true
            })
        }
    }

    closePopUp = (type) => {
        if (type === "1") {
            this.setState({
                isOutOfTown: false
            })
        } else if (type === "2") {
            this.setState({
                isReschedule: false
            })
        } else if (type === "3") {
            this.setState({
                isOverPhone: false
            })
        } else if (type === "4") {
            this.setState({
                isOverVideo: false
            })
        }
    }

    onSubmit = (type) => {
        let obj = {};
        if (type === "1") {
            obj = {
                type: "1",
                name: "Book Out Of Town Interpreter",
                value: ""
            }

            this.closePopUp(type);
            this.props.toggle(obj);
        } else if (type === "2") {
            if (this.state.rescheduleDate !== "") {
                obj = {
                    type: "2",
                    name: "Reschedule a Different Date",
                    value: SetDatabaseDateFormat(this.state.rescheduleDate)
                }

                this.closePopUp(type);
                this.props.toggle(obj);
            } else {
                toast.error("Please select appointment date")
            }
        } else if (type === "3") {
            obj = {
                type: "3",
                name: "Book Phone Interpreter",
                value: ""
            }

            this.closePopUp(type);
            this.props.toggle(obj);
        } else if (type === "4") {
            obj = {
                type: "4",
                name: "Book Video Interpreter",
                value: ""
            }

            this.closePopUp(type);
            this.props.toggle(obj);
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* <ToastContainer hideProgressBar theme="colored" /> */}

                <Modal show={this.props.value.jobAdvanceModal} onHide={this.handleClose} dialogClassName="my-modal_out border-radius-2" size="lg" centered>
                    {/* <Modal.Header>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div class="filter-head-gg _fl document-hdr">
                            <button type="button" class="close" data-dismiss="modal" onClick={this.handleClose}>
                                <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                            </button>
                        </div>
                        <div className="pop_hd">
                            <img src={ImageName.IMAGE_NAME.ALRT_ICON} />
                            <p>In the event that our local interpreters are not available, <br />what would you like <span>7C</span> to do</p>
                        </div>
                        <div className="row">
                            {/* <div className="mod-ppop-inr">
                                <div className="mod-ppop-inr-und">
                                    <div className="web-form-bx">
                                        <div className="frm-label">&nbsp;</div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input type="radio" name="radio" checked={this.state.isSelect} onChange={this.clickChangeYes} />
                                                <span className="checkmark3"></span>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input type="radio" name="radio" checked={!this.state.isSelect} onChange={this.clickChangeNo} />
                                                <span className="checkmark3"></span>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6 book_se">
                                <button onClick={() => this.openPopUp("1")}>Book Out Of Town Interpreter</button>
                                <p>Additional Fee Applies</p>
                            </div>
                            <div className="col-md-6 book_se">
                                <button onClick={() => this.openPopUp("2")}>Reschedule To a Different Date</button>
                                <p>Choose a different Appointment Date</p>
                            </div>
                            <div className="pops_undr_pop mod-ppop-inr" hidden={!this.state.isOutOfTown}>
                                <div class="filter-head-gg _fl document-hdr">

                                    <button type="button" class="close" data-dismiss="modal" onClick={() => this.closePopUp("1")}><img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} /></button>
                                </div>
                                <div className="mod-ppop-inr-und">
                                    <p>It looks like you are not approved for Out of <br />Town interpreter. Would you like us to reach out <br /> To the authorised person?</p>
                                    <div className="web-form-bx">
                                        <div className="frm-label">&nbsp;</div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                {this.state.countrySpecificDialect ?
                                                    <input type="radio" name="radio" checked={true} value="yes" onChange={this.countrySpecific} disabled /> :
                                                    <input type="radio" name="radio" checked={false} value="yes" onChange={this.countrySpecific} disabled />}
                                                <span className="checkmark3"></span> Yes</label>
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                {this.state.countrySpecificDialect ?
                                                    <input type="radio" name="radio" checked={false} value="no" onChange={this.countrySpecific} disabled /> :
                                                    <input type="radio" name="radio" checked={true} value="no" onChange={this.countrySpecific} disabled />}
                                                <span className="checkmark3"></span> No</label>
                                        </div>


                                        {/* <div className="pops_undr_pop mod-ppop-inr">
                                            <div className="filter-head-gg _fl document-hdr">
                                                <button type="button" className="close" data-dismiss="modal">
                                                    <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                                                </button>
                                            </div>
                                            <p>
                                                <strong>
                                                    Oopps! No Supervisior was assigned to you
                                                </strong>
                                            </p>
                                            <button type="button" className="blue">Redirect to the authorised person</button>
                                            <button type="button" className="blue">Go back to thr previous page</button>

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="pops_undr_pop mod-ppop-inr" hidden={!this.state.isReschedule}>
                                <div className="filter-head-gg _fl document-hdr">
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.closePopUp("2")}>
                                        <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                                    </button>
                                </div>
                                <p>
                                    <strong>
                                        Reschedule Job Date
                                    </strong>
                                </p>
                                <div className="web-form-bx">

                                    <div className="frm-label">
                                        Appointment Date*
                                    </div>

                                    <div
                                        className="input-group"
                                        style={{
                                            width: "100%",
                                            borderRadius: "9px",
                                            height: "43px",
                                            border: "1px solid #ced4da",
                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                        }}
                                    >
                                        <div style={{ width: "80%", padding: "8px" }}>
                                            <span>{this.state.rescheduleDate}</span>
                                        </div>
                                        <div style={{ width: "20%" }}>
                                            <a style={{ float: "right" }}>
                                                <DatePicker
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    adjustDateOnChange
                                                    minDate={this.state.startDate}
                                                    onChange={(date) =>
                                                        this.appointmentdateChange(date)
                                                    }
                                                    customInput={<Schedule />}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="blue" onClick={() => this.onSubmit("2")}>Reschedule</button>
                                <button type="button" className="blue" onClick={() => this.closePopUp("2")}>Cancel</button>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 book_se">
                                <button onClick={() => this.openPopUp("3")}>Book Phone Interpreter</button>
                                {/* <p>Billed Per Minute*</p>
                                <p>*Subjected To Minimums</p> */}
                            </div>
                            <div className="col-md-6 book_se">
                                <button onClick={() => this.openPopUp("4")}>Book Video Interpreter</button>
                                {/* <p>Billed Per Minute*</p>
                                <p>*Subjected To Minimums</p> */}
                            </div>
                            <div className="pops_undr_pop mod-ppop-inr" hidden={!this.state.isOverPhone}>
                                <div className="filter-head-gg _fl document-hdr">
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.closePopUp("3")}>
                                        <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                                    </button>
                                </div>
                                <p>
                                    {/* <strong> */}
                                    Are you sure, want to book job over phone interpreter?
                                    {/* </strong> */}
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" className="blue" onClick={() => this.onSubmit("3")}>Submit</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" className="blue" onClick={() => this.closePopUp("3")}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div className="pops_undr_pop mod-ppop-inr" hidden={!this.state.isOverVideo}>
                                <div className="filter-head-gg _fl document-hdr">
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.closePopUp("4")}>
                                        <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                                    </button>
                                </div>
                                <p>
                                    Are you sure, want to book job over video interpreter?
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.props.value.isBtnSubmit ?
                                            <button type="button" className="blue">
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </button> :
                                            <button type="button" className="blue" onClick={() => this.onSubmit("4")}>Submit</button>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" className="blue" onClick={() => this.closePopUp("4")}>Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

class Schedule extends React.Component {
    render() {
        const { onClick } = this.props;
        return (
            <img
                style={{
                    width: "35px",
                    height: "37px",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
                src={ImageName.IMAGE_NAME.CALENDER4}
                onClick={onClick}
            />
        );
    }
}